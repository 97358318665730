import React, { useContext } from 'react';
import { ThemeContext } from '@websites/components';

// TODO refactor to merge with ProgressButton

const buttonClasses = ({
  bg,
  text,
  border,
  large,
  inverted,
  cta,
  disabled,
}) => `select-none 
  ${inverted ? bg('base-dark') : 'bg-transparent'} 
  ${cta ? bg('highlight-dark') : 'bg-transparent'} 
  ${!cta && `border ${border('base-dark')}`}
  uppercase tracking-wider text-base 
  ${text(inverted ? 'base-light' : 'base-dark')} 
  ${text(cta ? 'accent-light' : 'base-dark')} 
  font-semibold
  ${large ? 'px-3' : 'px-3'} 
  py-4 min-w-10 
  ${large && 'w-full'} 
  rounded-none
  ${disabled ? 'opacity-25 cursor-default' : 'hover:opacity-95 active:opacity-100 cursor-pointer'}
`;

export const LinkButton = ({
  large = false,
  inverted = false,
  cta = false,
  children,
  ...rest
}) => {
  const { bg, text, border } = useContext(ThemeContext);

  return (
    <a
      className={buttonClasses({
        bg,
        text,
        border,
        large,
        inverted,
        cta,
        disabled: rest.disabled,
      })}
      rel="noopener"
      {...rest}
    >
      {children}
    </a>
  );
};

export const Button = ({
  large = false,
  inverted = false,
  cta = false,
  children,
  ...rest
}) => {
  const { bg, text, border } = useContext(ThemeContext);

  return (
    <button
      type="button"
      className={buttonClasses({
        bg,
        text,
        border,
        large,
        inverted,
        cta,
        disabled: rest.disabled,
      })}
      {...rest}
    >
      {children}
    </button>
  );
};
