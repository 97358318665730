import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faShoppingCart,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Layout, ThemeContext } from '@websites/components';
import { fireClickAnalyticsEvent } from '@websites/utils';
import { LinkButton } from '../components/common/Button';
import { getShopifyClient } from '../utils/shopifyClient';
import { formatCurrency } from '../utils/formatCurrency';
import { fireCartAnalyticsEvent } from '../utils/analytics';

import { YellowBusinessContext } from '../contexts/YellowBusinessProvider';
import { CartContext } from '../contexts/CartProvider';

const quantityRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Cart = () => {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);
  const cart = React.useContext(CartContext);
  const hasTheme = !!yellowBusiness?.website?.theme;
  const { text } = React.useContext(ThemeContext);

  const [checkout, setCheckout] = React.useState<any>(null);
  const [cartIsBeingUpdated, setCartIsBeingUpdated] = React.useState(true);

  React.useEffect(() => {
    if (cart.checkoutId) {
      setCartIsBeingUpdated(true);
      getShopifyClient()
        .checkout.fetch(cart.checkoutId)
        .then((checkoutResponse) => {
          setCheckout(checkoutResponse);
          setCartIsBeingUpdated(false);
        })
        .catch((err) => {
          console.error('Error while fetching checkout: ', err);
          setCartIsBeingUpdated(false);
        });
    }
  }, [cart]);

  const cartTotalWithoutTax = checkout?.lineItems
    ?.filter((li) => li?.variant?.price && li?.quantity) // fix for a strange issue where a cart loads with variants but those variants have null price
    ?.map((li) => Number.parseFloat(li.variant.price) * 1.0 * li.quantity)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const removeFromCart = async (lineItem) => {
    const shopify = getShopifyClient();
    setCartIsBeingUpdated(true);
    try {
      const updatedCheckout = await shopify.checkout.removeLineItems(
        cart.checkoutId,
        [lineItem.id],
      );
      cart.addItemsToCartAndSave(-1 * lineItem.quantity);
      setCheckout(updatedCheckout);
      setCartIsBeingUpdated(false);
      fireCartAnalyticsEvent(
        'remove',
        `${lineItem.title}: ${lineItem.variant.title}`,
      );
    } catch (err) {
      console.error('Error while removing item from cart', err);
      setCartIsBeingUpdated(false);
    }
  };

  const updateCartQuantity = async (lineItem, newQuantity) => {
    const shopify = getShopifyClient();
    setCartIsBeingUpdated(true);
    try {
      const difference = newQuantity - lineItem.quantity;
      const lineItemsToUpdate = [{ id: lineItem.id, quantity: newQuantity }];
      const updatedCheckout = await shopify.checkout.updateLineItems(
        cart.checkoutId,
        lineItemsToUpdate,
      );
      cart.addItemsToCartAndSave(difference);
      setCheckout(updatedCheckout);
      setCartIsBeingUpdated(false);
    } catch (err) {
      console.error('Error while updating quantity for item in cart', err);
      setCartIsBeingUpdated(false);
    }
  };

  const onCheckout = () => {
    setCartIsBeingUpdated(true);
    if (typeof window !== 'undefined') {
      fireClickAnalyticsEvent('button_click', 'checkout');
      const redirectURL = checkout.webUrl;
      window.location.assign(redirectURL);
    }
  };

  return (
    <>
      {hasTheme && (
        <Layout
          isEcommerce
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
          pageName="Cart"
        >
          <div className="mx-auto w-full">
            <div className="uppercase tracking-widest font-bold text-xl pb-5 md:pb-10 lg:pb-16 text-center space-x-3 flex items-baseline justify-center">
              <h1>Your Cart</h1>
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            <div className="w-full px-6 md:px-8 lg:px-16 py-4 md:py-6 lg:py-6 shadow-md bg-white">
              {/* loading */}
              {!checkout && cartIsBeingUpdated && (
                <div className="flex flex-col items-center space-y-5 py-16 mb-8">
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                  <div className="text-gray-700 text-sm">Loading cart</div>
                </div>
              )}

              {/* empty */}
              {(!cart.checkoutId || checkout?.lineItems?.length === 0) && !cartIsBeingUpdated && (
                <div className="flex justify-center py-16 text-gray-700">
                  Your cart is empty.
                </div>
              )}

              {/* cart */}
              {checkout?.lineItems?.length > 0 && (
                <div className="relative">

                  {/* cart updating */}
                  {cartIsBeingUpdated && (
                    <div className="absolute top-0 w-full h-full bg-white flex items-center justify-center z-50 text-3xl" style={{ opacity: 0.9 }}>
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                    </div>
                  )}

                  {/* cart items */}
                  <div>
                    {checkout.lineItems.map((item) => (
                      <div key={item.id} className="flex space-x-5 py-6 md:py-10 border-b border-gray-200">
                        <div className="w-1/6 flex items-center">
                          <img src={item.variant.image.src} className="object-cover" alt="" />
                        </div>
                        <div className=" w-5/6 flex flex-col space-y-3 justify-center">
                          <div className="flex justify-between">
                            <div>
                              <div className="font-medium">{item.title}</div>
                              {item.variant.title !== 'Default Title' && (
                                <div className="text-gray-600 text-xs uppercase tracking-wide">
                                  {item.variant.title}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col items-start space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center">
                            <div className="flex items-baseline space-x-2">
                              <div>
                                {formatCurrency(item.variant.price, item.quantity)}
                              </div>
                              {item.quantity > 1 && (
                                <div className="text-sm text-gray-600">
                                  (
                                    {formatCurrency(item.variant.price)}
                                    &nbsp;ea.
                                  )
                                </div>
                              )}
                            </div>
                            <div className="flex items-center space-x-5 justify-between w-full md:w-auto">
                              <div className="flex space-x-2 items-center">
                                <div className="text-gray-600 uppercase tracking-widest text-xs sm:text-sm"> Qty </div>
                                <select
                                  value={Number.parseInt(item.quantity)}
                                  className="form-select border-none text-sm"
                                  onChange={(e) => updateCartQuantity(item, Number.parseInt(e.target.value))}
                                >
                                  {quantityRange.map((qty) => (
                                    <option key={qty} value={qty}>
                                      {qty}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <button type="button" onClick={() => removeFromCart(item)}>
                                <FontAwesomeIcon icon={faTrash} className="opacity-50 hover:text-red-500 hover:opacity-100" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* total */}
                  <div className="py-6 md:py-8 lg:py-12">
                    <div className="flex justify-end items-baseline space-x-3">
                      <div className="text-sm uppercase tracking-widest">Total</div>
                      <div className="text-xl font-bold text-right">
                        {formatCurrency(cartTotalWithoutTax)}
                      </div>
                    </div>
                    <div className="text-gray-600 text-right text-xs mt-2">
                      <div>Price includes GST.</div>
                      <div>Shipping is additional and calculated at checkout.</div>
                    </div>
                  </div>

                  {/* cart footer */}
                  <div className="flex flex-col-reverse md:flex-row justify-between">
                    <Link
                      to="/#catalog"
                      className={`text-xs uppercase tracking-widest flex space-x-2 items-center justify-center hover:underline ${text('base-dark')} opacity-50`}
                      onClick={() => fireClickAnalyticsEvent('link_click', 'back_to_catalog_from_cart_page')}
                    >
                      <FontAwesomeIcon icon={faLongArrowAltLeft} />
                      <span>Continue shopping</span>
                    </Link>

                    <div className="py-4 flex justify-end space-y-5">
                      <LinkButton cta large onClick={onCheckout}>
                        <div className="flex space-x-2 items-center justify-center">
                          <span>Checkout</span>
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </LinkButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Cart;
